@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Hind Kochi Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Hind Kochi Regular'), url('./asset/fonts/hind-kochi-cufonfonts-webfont/HindKochi-Regular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Hind Kochi Light';
  font-style: normal;
  font-weight: normal;
  src: local('Hind Kochi Light'), url('./asset/fonts/hind-kochi-cufonfonts-webfont/HindKochi-Light.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Hind Kochi Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Hind Kochi Medium'), url('./asset/fonts/hind-kochi-cufonfonts-webfont/HindKochi-Medium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Hind Kochi SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Hind Kochi SemiBold'), url('./asset/fonts/hind-kochi-cufonfonts-webfont/HindKochi-SemiBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Hind Kochi Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Hind Kochi Bold'), url('./asset/fonts/hind-kochi-cufonfonts-webfont/HindKochi-Bold.woff') format('woff');
  }

  @font-face {
    font-family: 'Bordeaux-Thin';
    src: url('./asset/fonts/bordeaux/Bordeaux-Thin.ttf');
  }
  @font-face {
    font-family: 'Bordeaux-Light';
    src: url('./asset/fonts/bordeaux/Bordeaux-Light.ttf');
  }
  @font-face {
    font-family: 'Bordeaux-Regular';
    src: url('./asset/fonts/bordeaux/Bordeaux-Regular.ttf');
  }
  @font-face {
    font-family: 'Bordeaux-Bold';
    src: url('./asset/fonts/bordeaux/Bordeaux-Bold.ttf');
  }

body {
  margin: 0;
  font-family: -apple-system, Montserrat, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
   --col-bg: #000000;
   --col-lgh: #F3F3F3;
   --col-hg: #CF4D7C;
   --col-hg-act: #8E2A4E;
}
.main-bg{
  background-image: linear-gradient(to right ,#161616,#101010,#101010);
}
.pos-pos{
  background-position-y: 30%;
}
.pos-pos-bio{
  background-position-y: 30%;
  background-position-x: 70%;
  background-size:40%;
  background-repeat: no-repeat;
}
.pos-pos-photos:hover{
  background-position-y: 30%;
}
.main-bg-md::before{
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-image: url('https://assets.digitalocean.com/labs/images/community_bg.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
.play-btn::before, 
.play-btn::after{
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #CF4D7C;
  z-index: -1;
  border-radius: 50%;
  opacity: 0.7;
}
.play-btn::before{
  animation: pulse 2s ease-out infinite;
}
.play-btn::after{
  animation: pulse 2s 1s ease-out infinite;
}
@keyframes pulse {
  100%{
    transform: scale(2);
    opacity: 0;
  }
}